<template>
  <div
    class="tab-pane fade show active"
    id="pills-1"
    role="tabpanel"
    aria-labelledby="pills-1-tab"
  >
    <div class="tourn-banner my-4">
      <div class="card flex-md-row justify-content-md-between">
        <div class="col-md-4 card-img-wrapper order-md-2">
          <img
            :src="
              tourbinary &&
              tourbinary[0] &&
              tourbinary[0].filepath
                | get_img(
                  'https://krstorage.s3.ap-northeast-2.amazonaws.com/files/tournament/06edda88fe1bb993db760ee25b8ad9fa.jpg'
                )
            "
          />
        </div>

        <div class="col-md-8 d-flex flex-column order-md-1">
          <div class="card-body">
            <span class="tourn-type badge rounded-pill"
              >{{ tournament_type(tournamentinfo.type) }} /
              {{ tournamentinfo.tour_rank_type | tour_rank_type }}</span
            >
            <h3 class="card-title">
              {{ tournamentinfo.title }}
            </h3>
          </div>

          <div class="card-footer">
            <div class="row gx-2 gy-1">
              <div
                class="col-auto text-date"
                v-if="
                  tournamentinfo.timezone_startdate &&
                  tournamentinfo.timezone_startdate.timezone_private
                "
              >
                <!-- 사용자 시간 -->
                <dfn
                  class="time-zone-mark badge rounded-pill bg-orange"
                  title="Connect time"
                  >CT</dfn
                >
                {{
                  tournamentinfo.timezone_startdate &&
                  tournamentinfo.timezone_startdate.timezone_private
                    | dateformat
                }}
                ~
                {{
                  tournamentinfo.timezone_startdate &&
                  tournamentinfo.timezone_enddate.timezone_private | dateformat
                }}
              </div>
              <div
                class="col-auto text-date"
                v-else-if="
                  tournamentinfo.timezone_startdate &&
                  tournamentinfo.timezone_startdate.timezone_common
                "
              >
                <!-- 서비스 시간 -->
                <dfn
                  class="time-zone-mark badge rounded-pill bg-green"
                  title="Standard time"
                  >ST</dfn
                >
                {{
                  tournamentinfo.timezone_startdate &&
                  tournamentinfo.timezone_startdate.timezone_common | dateformat
                }}
                ~
                {{
                  tournamentinfo.timezone_startdate &&
                  tournamentinfo.timezone_enddate.timezone_common | dateformat
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="border-top-dgray mt-4">
      <div class="row g-0 border-bottom">
        <div class="col-4 col-lg-2 bg-gray row g-0 g-0 align-items-center">
          <label class="d-table-cell">{{
            $t("title.common.information")
          }}</label>
        </div>
        <div class="col-8 col-lg-10 d-flex">
          <p class="d-table-cell align-self-center">
            {{ tournamentinfo.remark }}
          </p>
        </div>
      </div>
      <div class="row g-0 border-bottom">
        <div class="col-4 col-lg-2 bg-gray row g-0 g-0 align-items-center">
          <label class="d-table-cell">{{ $t("title.common.course") }}</label>
        </div>
        <div class="col-8 col-lg-10 d-flex">
          <p class="d-table-cell align-self-center">
            <span v-for="(course, index) in tournamentcourse" :key="index"
              >{{ index === 0 ? "" : ", " }}{{ course.coursename }}</span
            >
          </p>
        </div>
      </div>

      <div class="row g-0 border-bottom">
        <div class="row g-0 col-4 col-lg-2 bg-gray align-item-center">
          <label class="d-table-cell align-self-center">{{
            $t("text.common.shortcourse")
          }}</label>
        </div>
        <div v-if="subcourselistcnt" class="col-8 col-lg-10">
          <div
            v-for="(anc, index1) in subcourselist"
            :key="'k_' + index1"
            class="subcourse"
          >
            <h6>{{ anc.subcoursename }}</h6>
            <small class="d-block text-dgray"
              >{{ $t("text.common.shortcourse") }} {{ anc.subcourseseq }}</small
            >
          </div>
        </div>
      </div>
      <div class="row g-0 border-bottom">
        <div class="row g-0 col-4 col-lg-2 bg-gray align-item-center">
          <label class="d-table-cell align-self-center">{{
            $t("title.common.point_allocation")
          }}</label>
        </div>
        <div class="col-8 col-lg-10 d-flex">
          <p class="d-table-cell align-self-center">
            <span>
              {{ tournamentinfo.tour_class | tour_class }}
              {{ $t("title.common.standard_point") }}
              {{
                tournamentinfo.oom_point == 1
                  ? "+ " + $t("title.common.oom_point")
                  : ""
              }}
            </span>
          </p>
        </div>
      </div>
    </div>

    <div class="border-top-dgray mt-5">
      <!-- <div class="row g-0">
        <div class="col-lg-2 row g-0 bg-gray border-bottom align-items-center">
          <label class="d-table-cell">매장</label>
        </div>
        <div class="col-lg-10 border-bottom">
          <p class="d-table-cell">
            <span v-for="(shop, index2) in tournamentshop" :key="index2"
              >{{ index2 === 0 ? "" : " / " }}{{ shop.shopname }}</span
            >
          </p>
        </div>
      </div> -->
      <div class="row g-0">
        <div class="col-lg-2 row g-0 bg-gray border-bottom align-items-center">
          <label class="d-table-cell">{{ $t("title.common.prizes") }}</label>
        </div>
        <div class="col-lg-10 border-bottom">
          <div class="row g-0 border-bottom">
            <div class="col-lg-12">
              <p class="d-table-cell">
                {{ tournamentprizeetc.etcprize || "Unregistered" }}
              </p>
            </div>
          </div>
          <div class="row g-0 border-bottom-0">
            <div class="col-lg-12">
              <p class="d-table-cell">
                <span
                  class="d-block"
                  v-for="(rank, index3) in tournamentprizerank"
                  :key="index3"
                  ><strong class="fw-500 text-black"
                    >{{ $t("text.common.top") }}{{ rank.rankno }}</strong
                  >
                  :
                  <span class="text-prize">{{ rank.rankprize | comma }}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-lg-2 bg-gray row g-0 align-items-center border-bottom">
          <label class="d-table-cell">{{
            $t("title.common.tournament_conditions")
          }}</label>
        </div>
        <div class="col-lg-10">
          <div class="row g-0 border-bottom-0">
            <div
              class="col-lg-6 d-table-cell d-flex flex-column align-items-stretch"
            >
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.game_mode")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center">
                    {{ tournamentinfo.mode }}
                  </p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.level")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center">
                    {{ tournament_level(tournamentinfo.level) }}
                  </p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.entry_limit")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center text-red">
                    {{ tournamentinfo.entrylimit | transToValue("∞") }}
                  </p>
                </div>
              </div>
              <div
                class="row g-0 border-bottom d-flex flex-row align-items-stretch h-100"
              >
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.mulligan")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center">
                    {{ tournamentinfo.mulligan }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 d-table-cell d-flex flex-column align-items-stretch"
            >
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.play_format")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center">
                    {{ tournamentinfo.hole }}H
                  </p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.wind")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center">{{ wind }}</p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.tournament.rank_type")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center">
                    {{ tournamentinfo.tour_rank_type | tour_rank_type }}
                  </p>
                </div>
              </div>
              <div class="row g-0 border-bottom">
                <div class="col-4 bg-lightgray row g-0 align-items-center">
                  <label class="d-table-cell">{{
                    $t("title.common.teebox")
                  }}</label>
                </div>
                <div class="col-8 d-flex">
                  <p class="d-table-cell align-self-center">
                    {{ $t("title.common.male") }}: {{ teemale }}<br />
                    {{ $t("title.common.female") }}: {{ teefemale }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row g-0 border-bottom-0">
            <div class="col-lg-3 bg-gray row align-items-center">
              <label class="d-table-cell">Handicap(No. of Play)</label>
            </div>
            <div class="col-lg-9">
              <p class="d-table-cell">{{ tournamentinfo.playhandicap }}</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <b-toast
      id="toast-require-login"
      toaster="position-fixed top-50 start-50 translate-middle"
      no-close-button
      no-auto-hide
      solid
      body-class="p-4 text-center"
    >
      <div class="avatar avatar-lg mt-2 bg-orange border-0">
        <h3 class="material-icons text-white">person</h3>
      </div>
      <h6 class="sub-title mt-3 mb-2">{{ $t("text.common.require_login") }}</h6>
      <p class="pt-1">{{ $t("text.common.go_login_page") }}</p>
      <div class="btn-bottom-wrapper mt-3">
        <b-button
          size="sm"
          variant="outline-secondary"
          :aria-label="$t('button.common.cancel')"
          @click="$bvToast.hide('toast-require-login')"
        >
          {{ $t("button.common.cancel") }}
        </b-button>
        <b-button
          size="sm"
          variant="secondary"
          :aria-label="$t('button.common.login')"
          @click="$router.push('/Login')"
        >
          {{ $t("button.common.login") }}
        </b-button>
      </div>
    </b-toast>

    <b-modal
      id="modal-entry-fee-payment"
      ref="modal-entry-fee-payment"
      size="md"
      centered
      scrollable
      no-close-on-backdrop
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header>
        <h3>{{ $t("text.common.entry_fee_payment") }}</h3>
        <b-button
          variant="close"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-entry-fee-payment')"
        >
        </b-button>
      </template>
      <template v-if="pay_flag == '1'">
        <p class="modal-subtitle lh-sm">
          {{ paytournamentinfo.tourdefine.title }}
        </p>
        <b-form
          class="filter-group row row-cols-sm-auto g-2 justify-content-end"
        >
          <div class="row g-0 table-type header-col p-keep">
            <div class="col-sm-4">
              {{ $t("title.common.player") }}
            </div>
            <div class="col-sm-8">
              <p class="d-flex flex-wrap">
                <strong class="me-1">{{ $t("text.common.id") }}</strong>
                <span>: {{ paytournamentinfo.player.player_id }}</span>
              </p>
              <p class="d-flex flex-wrap mt-1">
                <strong class="me-1">{{ $t("title.common.nickname") }}</strong>
                <span class="text-nickname"
                  >: {{ paytournamentinfo.player.nickname }}</span
                >
              </p>
            </div>

            <div class="col-sm-4">
              {{ $t("title.common.entry_limit") }}
            </div>
            <div class="col-sm-8 text-red">
              {{ paytournamentinfo.tour_results.total_game || 0 }} /
              {{ paytournamentinfo.tourdefine.entrylimit | transToValue("∞") }}
            </div>

            <div class="col-sm-4">
              {{ $t("text.common.entry_fee") }}
            </div>
            <div class="col-sm-8 text-price">
              {{ 5.0 | toFixed | price_unit }}
            </div>

            <div class="col-sm-4">
              {{ $t("text.common.card_info") }}
            </div>
            <div class="col-sm-8 text-price">
              <div class="row g-2">
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="1234 1234 1234 1234"
                    :aria-label="$t('text.common.card_number')"
                    name="CardNumber"
                    autocomplete="off"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="MM / YY"
                    :aria-label="$t('text.common.card_expiry')"
                    name="CardExpiry"
                    autocomplete="off"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="CVC"
                    :aria-label="$t('text.common.card_cvc')"
                    name="CardCVC"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              {{ $t("text.common.cardholder") }}
            </div>
            <div class="col-sm-8">
              <div class="row g-2">
                <div class="col-6">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('title.membership.first_name')"
                    :aria-label="$t('title.membership.first_name')"
                    name=""
                    autocomplete="off"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('title.membership.last_name')"
                    :aria-label="$t('title.membership.last_name')"
                    name=""
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              {{ $t("text.common.country_or_region") }}
            </div>
            <div class="col-sm-8">
              <div class="row g-2">
                <div class="col-12">
                  <select name="countryno">
                    <option value="">{{ $t("title.common.choose") }}</option>
                  </select>
                </div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control text-capitalize"
                    :placeholder="$t('text.common.zip_code')"
                    :aria-label="$t('text.common.zip_code')"
                    name=""
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </template>
      <!-- 결제 정상 완료 -->
      <template v-if="pay_flag == '2'">
        <div class="mt-5 mb-4 text-center">
          <p class="material-icons fs-48 text-success">check_circle</p>
          <h6 class="sub-title my-2 pt-1 text-capitalize">
            {{ $t("text.common.payment_complete") }}
          </h6>
          <p class="pt-1 fs-14 lh-sm">
            {{ $t("text.common.payment_successfully") }}
          </p>
        </div>

        <div class="card">
          <div class="card-body px-3 py-2 fs-14">
            <div class="row g-0 table-type header-col p-keep border-0">
              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.payment_method") }}
              </div>
              <div class="col-sm-8">Credit Card</div>

              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.payment_amount") }}
              </div>
              <div class="col-sm-8 text-price">
                {{ 5.0 | toFixed | price_unit }}
              </div>

              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.payment") }} {{ $t("text.common.number") }}
              </div>
              <div class="col-sm-8">1002624537</div>

              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.payor") }}
              </div>
              <div class="col-sm-8">지불인</div>

              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.date") }}
              </div>
              <div class="col-sm-8 text-date">2024-11-05 05:23:12</div>

              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.company") }}
              </div>
              <div class="col-sm-8">XGAU</div>
            </div>
          </div>
        </div>
      </template>
      <!-- 참가 제한 초과 -->
      <template v-if="pay_flag == '0'">
        <div class="my-5 text-center">
          <p class="material-icons fs-48 text-orange">report</p>
          <h6 class="sub-title my-2 pt-1 text-capitalize">
            {{ $t("text.common.entry_limit_exceeded") }}
          </h6>
          <p class="pt-1 fs-14 lh-sm">
            {{ $t("text.common.participate_another_tourn") }}
          </p>
        </div>
      </template>
      <!-- 결제 실패 -->
      <template v-if="pay_flag == '3'">
        <div class="mt-5 mb-4 text-center">
          <p class="material-icons fs-48 text-danger">dangerous</p>
          <h6 class="sub-title my-2 pt-1 text-capitalize">
            {{ $t("text.common.payment_failed") }}
          </h6>
          <p class="pt-1 fs-14 lh-sm">
            {{ $t("text.common.payment_unsuccessful") }}<br />
            {{ $t("text.common.please_try_again") }}
          </p>
        </div>

        <div class="card">
          <div class="card-body px-3 py-2 fs-14">
            <div class="row g-0 table-type header-col p-keep border-0">
              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.error") }} {{ $t("text.common.code") }}
              </div>
              <div class="col-sm-8">404</div>

              <div class="col-sm-4 bg-transparent">
                {{ $t("text.common.error") }} {{ $t("text.common.reason") }}
              </div>
              <div class="col-sm-8">Card Company Failure</div>
            </div>
          </div>
        </div>
      </template>
      <template #modal-footer>
        <!-- <b-button
          size="md"
          variant="outline-secondary"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-entry-fee-payment')"
        >
          {{ $t("button.common.close") }}
        </b-button> -->

        <!-- <b-button
          size="md"
          variant="outline-secondary"
          :aria-label="$t('button.common.confirm')"
          @click="$bvModal.hide('modal-entry-fee-payment')"
        >
          {{ $t("button.common.confirm") }}
        </b-button> -->

        <b-button
          size="md"
          v-if="pay_flag == '1'"
          variant="outline-secondary"
          :aria-label="$t('button.common.cancel')"
          @click="$bvModal.hide('modal-entry-fee-payment')"
        >
          {{ $t("button.common.cancel") }}
        </b-button>

        <b-button
          size="md"
          v-else-if="pay_flag == '2'"
          variant="outline-secondary"
          :aria-label="$t('button.common.done')"
          @click="$bvModal.hide('modal-entry-fee-payment')"
        >
          {{ $t("button.common.done") }}
        </b-button>

        <b-button
          size="md"
          v-else
          variant="outline-secondary"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-entry-fee-payment')"
        >
          {{ $t("button.common.close") }}
        </b-button>

        <b-button
          v-if="pay_flag == '1'"
          size="md"
          variant="secondary"
          :aria-label="$t('button.common.submit')"
          type="button"
          @click="onPaySubmit"
        >
          {{ $t("button.common.submit") }}
        </b-button>

        <!-- <b-button
          size="md"
          variant="secondary"
          :aria-label="$t('button.common.try_again')"
        >
          {{ $t("button.common.try_again") }}
        </b-button> -->
      </template>
    </b-modal>
  </div>
</template>

<script>
import ax from "@/api/tournament";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "TournamentCondition",
  data() {
    return {
      tournamentno: this.$route.params.id,
      tournamentinfo: [
        {
          title: "",
          remark: "",
          startdate: "",
          enddate: "",
          mode: "",
          hole: "",
          level: "",
          wind: "",
          mulligan: "",
          entrylimit: "",
          teeboxman: "",
          teeboxwoman: "",
          playhandicap: "",
        },
      ],
      tournamentcourse: [],
      tournamentshop: [],
      tournamentprizeetc: [
        {
          etcprize: "",
        },
      ],
      tournamentprizerank: [],

      wind: "",
      teemale: "",
      teefemale: "",
      subcourselist: [],
      tourbinary: [],
      subcourselistcnt: 0,
      pay_form: {},
      pay_result: {},
      pay_flag: 1,
      login: sessionStorage.getItem("token") ? true : false,
      paytournamentinfo: {
        player: {},
        tour_results: {},
        tourdefine: {},
        tour_order_list: [],
      },
    };
  },
  methods: {
    participation(tour_pk) {
      if (!this.login) {
        this.$bvToast.show("toast-require-login");
        return false;
      }
      this.post_stripeinit(tour_pk);
    },

    post_stripeinit(tour_pk) {
      ax.post_stripeinit(tour_pk, (flag, data) => {
        // console.log(flag, data);
        if (flag) {
          this.paytournamentinfo = data.tournamentinfo;
          this.pay_form.tour_pk = data.tournamentinfo.tourdefine.tour_pk;
          this.pay_flag = 1;
          this.$bvModal.show("modal-entry-fee-payment");
        } else {
          this.pay_flag = 0;
          alert(data.message);
        }
      });
    },
    onPaySubmit(event) {
      event.preventDefault();

      ax.post_order_insert(this.pay_form, (flag, data) => {
        // console.log(flag, data);
        alert(data.message);
        this.pay_result = data.pay_result;
        if (flag) {
          this.pay_flag = 2;
        } else {
          this.pay_flag = 3;
        }
      });
    },
    get_tournamentcondition() {
      ax.get_tournamentcondition(this.tournamentno, (flag, data) => {
        // console.log(flag, data);
        if (flag) {
          this.tournamentinfo = data.tournamentinfo;
          this.tournamentcourse = data.tournamentcourse;
          this.tournamentshop = data.tournamentshop;
          this.tournamentprizeetc = data.tournamentprizeetc;
          this.tournamentprizerank = data.tournamentprizerank;

          this.subcourselist = data.subcourselist;
          this.subcourselistcnt = data.subcourselistcnt;
          this.tourbinary = data.tourbinary;
          this.change_wind(this.tournamentinfo.wind);
          this.change_male(this.tournamentinfo.teeboxman);
          this.change_female(this.tournamentinfo.teeboxwoman);
        } else {
          alert(data.message);
        }
      });
    },
    // change_wind(wind) {
    //   if (wind === "0") {
    //     this.wind = this.$i18n.t("text.common.calm");
    //   } else if (wind === "1") {
    //     this.wind = this.$i18n.t("text.common.light");
    //   } else if (wind === "2") {
    //     this.wind = this.$i18n.t("text.common.moderate");
    //   } else if (wind === "3") {
    //     this.wind = this.$i18n.t("text.common.strong");
    //   } else if (wind === "4") {
    //     this.wind = this.$i18n.t("text.common.random");
    //   } else {
    //     this.wind = wind;
    //   }
    // },
    // change_male(teeboxman) {
    //   if (teeboxman === "1") {
    //     this.teemale =
    //       this.$i18n.t("text.common.pro") +
    //       "(" +
    //       this.$i18n.t("text.common.blue") +
    //       ")";
    //   } else if (teeboxman === "2") {
    //     this.teemale =
    //       this.$i18n.t("text.common.regular") +
    //       "(" +
    //       this.$i18n.t("text.common.white") +
    //       ")";
    //   } else if (teeboxman === "3") {
    //     this.teemale =
    //       this.$i18n.t("text.common.lady") +
    //       "(" +
    //       this.$i18n.t("text.common.red") +
    //       ")";
    //   } else {
    //     this.teemale = teeboxman;
    //   }
    // },
    // change_female(teeboxwoman) {
    //   if (teeboxman === "1") {
    //     this.teemale =
    //       this.$i18n.t("text.common.pro") +
    //       "(" +
    //       this.$i18n.t("text.common.blue") +
    //       ")";
    //   } else if (teeboxman === "2") {
    //     this.teemale =
    //       this.$i18n.t("text.common.regular") +
    //       "(" +
    //       this.$i18n.t("text.common.white") +
    //       ")";
    //   } else if (teeboxman === "3") {
    //     this.teemale =
    //       this.$i18n.t("text.common.lady") +
    //       "(" +
    //       this.$i18n.t("text.common.red") +
    //       ")";
    //   } else {
    //     this.teefemale = teeboxwoman;
    //   }
    // },
  },
  created() {
    this.get_tournamentcondition();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  mixins: [myMixin],
};
</script>
